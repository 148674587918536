import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@bs24/core/services/auth.service';
import { WebappService } from '@bs24/core/services/webapp.service';

/*
 * Format a value to the locale currency and money format
 * Usage:
 *   integer | moneyFormat
 * Example:
 *   {{ 12300 | moneyFormat }}
 *   formats to: &euro;123.00
*/

/**
 * Format a value to the locale currency and money format, output: 'formats to: &euro;123.00'
 */
@Pipe({
  name: 'moneyFormat',
  standalone: true
})
export class MoneyFormatPipe extends CurrencyPipe implements PipeTransform {

  /**
   * The constructor
   */
  currency: string | undefined;

  constructor(private authService: AuthService, webapp: WebappService) {
    super('en');
    this.currency = webapp.webappConfig().currencies[0].symbol;
    authService.accountLogged$.subscribe(user => {
      if (user) {
        this.currency = user.currencies[0].symbol;
      }
    });
  }

  // @ts-ignore
  override transform(value: number, fromCents = true, displayCurrency = true, showCents = true, currency = this.currency || 'EUR'): string | null {
    if (!value) {
      value = 0;
    }

    const digitInfo = showCents ? '1.2-2' : '1.0-2';

    value = fromCents ? (value / 100) : value;

    if (displayCurrency) {
      return super.transform(value, currency, 'code', digitInfo);
    } else {
      return super.transform(value, currency, '', digitInfo);

    }
  }
}
